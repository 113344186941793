import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LoginService } from './core/services/login.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(loginService: LoginService, private router: Router) {
    if (loginService.Autenticado() == true) {
      var path = location.pathname.split("/")[1];
      // console.log(location.pathname);
      if (path == "/") {
        this.router.navigate(['campanhas']);
      }
      if (path == undefined) {
        this.router.navigate(['campanhas']);
      }
    };

    this.router.events.subscribe((event) => {
      if(event instanceof NavigationStart) {
        if (event.url == "/") {
          this.router.navigate(['campanhas']);
        }
      }
    });
  }

}
