import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        label: 'Principal',
        isTitle: true
    },
    {
        label: 'Início',
        icon: 'home',
        link: '/dashboard'        
    },
    {
        label: 'Exibidores',
        icon: 'users',
        link: '/exibidores'        
    },
    {
        label: 'Configurações',
        isTitle: true
    },
    {
        label: 'Equipe',
        icon: 'shield',
        link: '/equipe',
    }    
];
