import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private loginService: LoginService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(this.loginService.ObjUsuarioLogado() == null || this.loginService.ObjUsuarioLogado() == undefined) {
            this.loginService.deslogar();
        } else {
          if (request.url.indexOf("googleapis") <= 0) {
              request = request.clone({
                  setHeaders: {
                      Authorization: `Bearer ${this.loginService.ObjUsuarioLogado().TokenAPI}`
                  }
              });
          };
        }

        return next.handle(request);
    }
}
