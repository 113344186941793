import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./core/guards/auth.guard";
import { LayoutComponent } from "./layouts/layout.component";

const routes: Routes = [
  {
    path: "acesso",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "relatorio",
    loadChildren: () =>
      import("./relatorio/relatorio.module").then((m) => m.RelatorioModule),
  },
  {
    path: "imprimir",
    loadChildren: () =>
      import("./imprimir/imprimir.module").then((m) => m.ImprimirModule),
  },
  // tslint:disable-next-line: max-line-length
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
