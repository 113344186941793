import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'img-order',
  templateUrl: './img-order.component.html',
  styleUrls: ['./img-order.component.scss']
})
export class ImgOrderComponent implements OnInit {
  @Input() sort;

  constructor() { }

  ngOnInit() {
  }

}
