import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sem-resultados',
  templateUrl: './sem-resultados.component.html',
  styleUrls: ['./sem-resultados.component.scss'],
})
export class SemResultadosComponent {

  @Input() mostrar = false;

  @Input() texto = '';
  @Input() subTexto = '';

  @Input() mostrarBotao = false;
  @Input() textoBotao = ' Novo';

  @Input() iconeBotao = 'plus';

  @Output() acaoBotao = new EventEmitter();

  constructor() { }

  cliqueBotao() {
      this.acaoBotao.emit();
  }
}
