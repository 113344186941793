import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppSettings, Settings } from 'src/app/app.settings';
import { Equipe } from 'src/app/core/models/equipe';
import { LoginUsuario } from 'src/app/core/models/login-usuario';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public configuracoes: Settings;


  constructor(public http: HttpClient, public appSettings: AppSettings) {
    this.configuracoes = this.appSettings.settings;

    //this.usuario.Autenticado = true;
  }

  Login(Usuario): Observable<LoginUsuario> {
    return this.http.post<LoginUsuario>(this.configuracoes.apiUrl + "/api/loginCheckooh/logar", Usuario);
  }

  redefinirSenha(Email): Observable<any> {
    return this.http.post(this.configuracoes.apiUrl + "/api/loginCheckooh/redefinirSenha", Email);
  }

  redefinirPrimeiroAcesso(Email): Observable<any> {
    return this.http.post(this.configuracoes.apiUrl + "/api/loginCheckooh/redefinirPrimeiroAcesso", Email);
  }

  redefinirSenhaTokenValidade(Token): Observable<any> {
    return this.http.post(this.configuracoes.apiUrl + "/api/loginCheckooh/redefinirSenhaTokenValidade", Token);
  }

  alterarSenha(usuario: LoginUsuario): Observable<any> {
    return this.http.post(this.configuracoes.apiUrl + "/api/loginCheckooh/alterarSenha", usuario);
  }

  primeiroAcessoTokenValidade(Token): Observable<any> {
    return this.http.post(this.configuracoes.apiUrl + "/api/loginCheckooh/primeiroAcessoTokenValidade", Token);
  }

  recusarTermo(usuario: LoginUsuario): Observable<any> {
    return this.http.post(this.configuracoes.apiUrl + "/api/loginCheckooh/recusaTermo", usuario);
  }

  primeiroAcessoTokenValidadeChecker(Token): Observable<any> {
    return this.http.post(this.configuracoes.apiUrl + "/api/loginCheckooh/primeiroAcessoTokenValidadeChecker", Token);
  }

  tokenConviteEmpresaValidadeChecker(Token): Observable<any> {
    return this.http.post(this.configuracoes.apiUrl + "/api/loginCheckooh/TokenConviteEmpresaValidadeChecker", Token);
  }

  aceitarConvite(Token): Observable<any> {
    return this.http.post(this.configuracoes.apiUrl + "/api/loginCheckooh/aceitarConvite", Token);
  }

  tokenConviteEmpresaValidade(Token): Observable<any> {
    return this.http.post(this.configuracoes.apiUrl + "/api/loginCheckooh/TokenConviteEmpresaValidade", Token);
  }

  aceitarConviteEmpresa(Token): Observable<any> {
    return this.http.post(this.configuracoes.apiUrl + "/api/loginCheckooh/aceitarConviteEmpresa", Token);
  }

  // // // // // // // // // // // // // // //
  // Serviços de controle da SessionStorage //
  // // // // // // // // // // // // // // //

  //Serviço seta 'true'/'false' para validar usuário logado
  setarAutenticado(bool) {
    sessionStorage.setItem("UsuarioLogado-CHECKOOH", bool);
  }

  //Serviço retorna se esta autenticado
  Autenticado(): boolean {
    if (sessionStorage.getItem("UsuarioLogado-CHECKOOH") == 'true') {
      return true;
    } else { return false; }

  }

  //Serviço seta Objeto do usuario logado
  setarObjUsuarioLogado(Usuario) {
    sessionStorage.setItem("UsuarioLogadoObj-CHECKOOH", Usuario);
  }

  //Serviço retorna obj com usuario logado
  ObjUsuarioLogado(): LoginUsuario {
    return JSON.parse(sessionStorage.getItem("UsuarioLogadoObj-CHECKOOH"));
  }

  setarLembrarUsuario(usuario) {
    localStorage.setItem("LembrarUsuario-CHECKOOH", usuario);
  }

  usuarioLembrar() {
    return localStorage.getItem("LembrarUsuario-CHECKOOH");
  }

  //Serviço remove os parametros de usuário logado
  deslogar() {
    sessionStorage.setItem("UsuarioLogadoObj-CHECKOOH", null);
    sessionStorage.setItem("UsuarioLogado-CHECKOOH", null)
  }

}
