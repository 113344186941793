export class Settings {
  public constructor() {
    this.apiUrl = "";
  }
  public apiUrl;
  // private _apiUrl;
  // private appSettings: AppSettings = new AppSettings();
  // public get apiUrl() {
  //   switch (environment.ambiente) {
  //     case Ambiente.CycloTeste:
  //       return this.appSettings.getHost();
  //       break;
  //     case Ambiente.CycloTesteLocal:
  //       return this.appSettings.getHost();
  //       break;

  //     default:
  //       break;
  //   }
  // if (environment.ambiente == Ambiente.CycloTeste) {

  // }
  // }
  // public set apiUrl(value) {
  //   this._apiUrl = value;
  // }
};
//

import { Injectable } from '@angular/core';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Ambiente } from 'src/environments/ambientes.enum';
import { environment } from './../environments/environment';

@Injectable()
export class AppSettings {
  constructor() {
    if (environment.production) {
      if (this.getHost() == 'http://localhost:4200') {
        //PRD
        this.settings.apiUrl =  'https://checkooh.azurewebsites.net';
        //DEV
        //this.settings.apiUrl =  'https://ecoinfoohapitestes.azurewebsites.net';
        //this.settings.apiUrl = 'http://localhost:47002';
      } else {
        //PRD
        this.settings.apiUrl = 'https://checkooh.azurewebsites.net';
        //DEV
        //this.settings.apiUrl = 'https://ecoinfoohapitestes.azurewebsites.net';
      }
    } else {
      if (this.getHost() == 'http://localhost:4200') {
        //PRD
        this.settings.apiUrl = 'https://checkooh.azurewebsites.net';
        //DEV
        //this.settings.apiUrl = 'https://ecoinfoohapitestes.azurewebsites.net';
        //this.settings.apiUrl = 'http://localhost:47002';

      } else {
        //PRD
        this.settings.apiUrl = 'https://checkooh.azurewebsites.net';
        //DEV
        //this.settings.apiUrl = 'https://ecoinfoohapitestes.azurewebsites.net';
      }
    }
    this.UF_BR = [
      { nome: "Acre", sigla: "AC" },
      { nome: "Alagoas", sigla: "AL" },
      { nome: "Amapá", sigla: "AP" },
      { nome: "Amazonas", sigla: "AM" },
      { nome: "Bahia", sigla: "BA" },
      { nome: "Ceará", sigla: "CE" },
      { nome: "Distrito Federal", sigla: "DF" },
      { nome: "Espírito Santo", sigla: "ES" },
      { nome: "Goiás", sigla: "GO" },
      { nome: "Maranhão", sigla: "MA" },
      { nome: "Mato Grosso", sigla: "MT" },
      { nome: "Mato Grosso do Sul", sigla: "MS" },
      { nome: "Minas Gerais", sigla: "MG" },
      { nome: "Pará", sigla: "PA" },
      { nome: "Paraíba", sigla: "PB" },
      { nome: "Paraná", sigla: "PR" },
      { nome: "Pernambuco", sigla: "PE" },
      { nome: "Piauí", sigla: "PI" },
      { nome: "Rio de Janeiro", sigla: "RJ" },
      { nome: "Rio Grande do Norte", sigla: "RN" },
      { nome: "Rio Grande do Sul", sigla: "RS" },
      { nome: "Rondônia", sigla: "RO" },
      { nome: "Roraima", sigla: "RR" },
      { nome: "Santa Catarina", sigla: "SC" },
      { nome: "São Paulo", sigla: "SP" },
      { nome: "Sergipe", sigla: "SE" },
      { nome: "Tocantins", sigla: "TO" }

    ]

    this.modalConfigRight.keyboard = false;
    this.modalConfigRight.backdrop = "static";
    this.modalConfigRight.windowClass = "right";

    this.modalConfigCenterLarge.size = "lg";
    this.modalConfigCenterLarge.keyboard = false;
    this.modalConfigCenterLarge.backdrop = "static";
    this.modalConfigCenterLarge.windowClass = "modal-center-sem-borda";
  }
  public settings = new Settings()

  // public settings = new Settings(
  //      "http://cycloinf005.azurewebsites.net"
  // )

  public modalConfigRight = new NgbModalConfig();
  public modalConfigCenterLarge = new NgbModalConfig();

  public verificarEmailRegex(email): boolean {
    var re = /\S+@\S+\.\S+/;
    return !re.test(email);
  }

  public mascaraTelefone(telefone: string): string {
    return telefone.length === 12 ? '(00) 00000-0000' : '(00) 0000-00009';
  }

  public mascaraInputCelular(celular: string): string {
    return celular.length === 11 ? '(00) 00000-0009' : '(00) 0000-00009';
  }

  timeoutRealcar(lista) {
    setTimeout(() => {
      lista.forEach(obj => {
        obj.Realcar = false;
      });
    }, 3000);
  }

  mascarar(texto: string, caracter: string = '*'): string {
    let tamanho = texto.length > 3 ? texto.length - 3 : texto.length / 2;

    for (let i = 0; i < tamanho; i++) {
      const element = texto[i];
      if (element != ' ' && element != '(' && element != ')' && element != '-' && element != '.')
        texto = texto.replace(element, caracter);
    }
    return texto;

  }

  mascararUltimosLivre(texto: string, caracter: string): string {
    for (let i = texto.length; i > 0; i--) {
      const element = texto[i];
      if (element != ' ')
        texto = texto.replace(element, caracter);
    }
    return texto;

  }

  mascararNome(texto: string): string {
    if (!texto) return "";

    let textoInicial = "";
    let textoFinal = "**** ******";
    if (texto.split(' ').length > 1) {
      textoInicial = texto.split(' ')[0];
      //textoFinal = texto.split(' ')[1]
    } else {
      textoInicial = texto;
    }

    return textoInicial + " " + textoFinal
  }

  mascararCPF(texto: string, caracter: string = '*'): string {
    if (!texto) return "";

    return `${texto[0]}${texto[1]}${texto[2]}.${caracter}${caracter}${caracter}.${caracter}${caracter}${caracter}-${caracter}${caracter}`;
  }

  mascararCPFCNPJ = function (cpfcnpj) {

    if (cpfcnpj != null && cpfcnpj.length == 14) {
        return cpfcnpj.replace(/\D/g,"").replace(/^(\d{2})(\d)/,"$1.$2").replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3").replace(/\.(\d{3})(\d)/,".$1/$2").replace(/(\d{4})(\d)/,"$1-$2");
    }
    else if (cpfcnpj != null && cpfcnpj.length == 11) {
        return cpfcnpj.replace(/\D/g, '').replace(/(\d{3})(\d)/,"$1.$2").replace(/(\d{3})(\d)/,"$1.$2").replace(/(\d{3})(\d{1,2})$/,"$1-$2");
    }
    else {
      return "";
    }
};

  public UF_BR: any = [];

  getHost() {

    var protocolo = location.protocol;
    var hostname = location.hostname;
    var port = location.port.length > 0 ? ":" + location.port : location.port;

    return protocolo + "//" + hostname + port;
  }

}
