import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'carregar-mais',
  templateUrl: './carregar-mais.component.html',
  styleUrls: ['./carregar-mais.component.scss']
})
export class CarregarMaisComponent implements OnInit {
  @Input() mostrar = false;
  @Input() mostrarCarregarMais = true;
  @Input() totalRegistros = 0;
  @Input() registrosAtuais = 0;

  @Output() acaoCarregarMais = new EventEmitter();
  @Output() acaoCarregarTudo = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  cliqueCarregarMais() {
    this.acaoCarregarMais.emit();
  }

  cliqueCarregarTudo() {
    this.acaoCarregarTudo.emit();
  }

}
